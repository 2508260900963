import { useState } from "react"
import { IProject } from "../../data/interfaces"
import { IFestProjectsProps, useFestProjects } from "../../data/store"
import EditProjectForm from "../about/EditProjectForm"
import { actions } from "../../data/actions"
import { UPDATE_SINGLE_ACTION } from "../../data/actionTypes"
import { AppToaster } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { Intent } from "@blueprintjs/core"
import { useParams } from "react-router"

const ProjectEditForm = () : JSX.Element => {
    const {landId} = useParams()
    const item: IProject = useFestProjects((state: IFestProjectsProps) => state.currentProject)
    const [isLoading, setIsLoading] = useState(false)  
    const [isLocked, setIsLocked] = useState( false )
    const [isReadyUpdate, setIsReadyUpdate] = useState(false)

    const onUpdate = (item: any) => { 
        let data: any = {
            data_type : "PEFestProject",
            id: item.id,
            item: {
                title: item.title,
                post_content: item.content, 
                thumbnail : item.thumbnail, 
                thumbnail_name: item.thumbnailName,
                form_fields : item.form_fields,
                tutor_name : item.tutorName,
                tutor_email : item.tutor_email,
                fmru_school : item.school,
                fmru_destrict : item.destrict,
                fmru_group_player : item.fmru_group_player,
            },
            landId: landId
        }   
        console.log(item)
        console.log(data)
        setIsLoading(true)
        setIsLocked( true )
        setIsReadyUpdate( true )
        actions(UPDATE_SINGLE_ACTION, data)
            .then(
                (response) => {
                    console.log( response )
                    setIsLoading(false)    
                    setIsLocked( false )                
                    setIsReadyUpdate(false)
                    AppToaster.show({
                        message: __("Success update")
                    })
                },
                () => {
                    setIsLoading(false)  
                    setIsLocked( false )  
                    setIsReadyUpdate(false)
                    AppToaster.show({
                        message: __("Unknown error. Try later"),
                        intent: Intent.DANGER
                    })
                }
            ) 

    }
    return <div className="pe-project-team-container minimal-colored my-3 pb-5 pt-1">
        <EditProjectForm
            {...item}
            track={ item.track?.id }
            school={item.fmru_school[0]?.id}
            destrict={item.fmru_destrict[0]?.id}
            isLocked={isLocked}
            isLoading={ isLoading }
            isReadyUpdate={ isReadyUpdate } 
            content={ item.post_content }
            tutorName={ item.tutor?.display_name }
            tutor_email={ item.tutor?.user_email }
            onUpdate={ onUpdate } 
        />
    </div>
} 
export default ProjectEditForm