import { useEffect, useState } from 'react'
import { actions } from '../data/actions'
import { default as mainAction} from "@/modules/pe-basic-module/data/actions"
import { GET_LAND_VERSION_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { SET_FESTIVAL_EXTENDS_ACTION, GET_FESTIVAL_DATA_ACTION } from '../data/actionTypes'
import { IFestival, ITrack, IHoneycombs, IGanre, IMilestone, ICategory, ICritery, ILand, IDestrict, DEFAULT_FILTERS } from '../data/interfaces'
import { useFestDataStore, useFestMyProjects, useFestStore } from '../data/store' 
import { ID } from '@/libs/interfaces/layouts'
import { useMainStore } from '@/settings/zustand'
import { useDataStore } from '@/modules/pe-basic-module/data/store'

const UPDATE_FEST_PERIOD = 300000

function useFestival( isLoader: boolean, festId: ID | null = null ) { 
    const [value, setValue] = useState<boolean>( isLoader )
    const getFestival = () => {
        const lastUpdateFestival: number = useFestDataStore.getState().lastUpdateFestival 
        const festival: IFestival = useFestDataStore.getState().festival  
        if(!value) {
            return
        }
        if( festival.url ) {
            setValue( false )
            return
        }
        if( Date.now() - lastUpdateFestival > UPDATE_FEST_PERIOD ) {
            mainAction(GET_LAND_VERSION_ACTION, { id: festId }) 
                .then(resp => {
                    // console.log( resp, festival.version )
                    if(resp !== festival.version) {
                        //clearFestival()
                        loadAndUpdateFestival( festId, setValue )
                    }
                    else {
                        setValue( false ) 
                    }
                })
        }
        else if(
            !lastUpdateFestival || 
            !festival.url || 
            ( festId !== useFestDataStore.getState().festId && !!festId )
        ) {
            //clearFestival()
            loadAndUpdateFestival(festId, setValue)
        }
        else { 
            setValue(false)
        }
    } 
    useEffect(() => getFestival(), [])
    return value 
}

function clearFestival() { 
    console.log("Festival cleared")
    const festId = "-1"
    useFestDataStore.setState({festId})
    useMainStore.setState({ landId: festId, event_types: [] })
    useFestDataStore.setState({ site: {} as ILand })
    useFestDataStore.getState().setFestival( {} as IFestival )
    useFestDataStore.getState().setTracks( [] )
    useFestDataStore.getState().setHoneycombs( [] )
    useFestDataStore.getState().setGanres( [] )
    useFestDataStore.getState().setMilestones( [] )
    useFestDataStore.getState().setCategories( [] )
    useFestDataStore.getState().setCriteries( [] )
    useDataStore.setState({PEFestProject: []})
    useFestMyProjects.getState().setMyProjects([])
    useFestStore.getState().setFilter( DEFAULT_FILTERS )
}
function loadAndUpdateFestival(festId:ID | null, setValue: (value: boolean) => void ) {
    useFestDataStore.setState({ festId })
    // useMainStore.setState({ landId: festId })
    actions(GET_FESTIVAL_DATA_ACTION, { id: festId })
        .then((response: any) => {
            const f  = response.getPEFestival as IFestival
            const site = response.getLand as ILand
            if(!site.id) {
                return
            }
            //console.log( response ) 
            useFestDataStore.getState().setFestival( f )
            useMainStore.setState({ 
                event_types: site.event_types
            })
            useFestDataStore.setState({ site })
            useFestDataStore.getState().setLastUpdateFestival( Date.now() )
            useMainStore.setState({ landId: festId })
            setValue(false)
            //console.log("Festival loaded")

            actions(SET_FESTIVAL_EXTENDS_ACTION, { id: festId })
                .then(resp => {
                    //console.log( "Festival extended loaded" )
                    const t  = resp.getPEFestTracks as ITrack[]
                    const d  = resp.getPEFestDestricts as IDestrict[]
                    const h  = resp.getPEFestHoneycombss as IHoneycombs[] 
                    const g  = resp.getPEFestGanres as IGanre[] 
                    const m  = resp.getPEFestMilestones as IMilestone[] 
                    const c  = resp.getPEFestCategorys as ICategory[] 
                    const cr = resp.getPEFestCriterys as ICritery[]
                    const sc = resp.getPEFestSchools as ICritery[] 
                    useDataStore.setState({PEFestTrack: t}) 
                    useDataStore.setState({PEFestDestrict: d}) 
                    useDataStore.setState({PEFestHoneycombs:  h}) 
                    useDataStore.setState({PEFestGanre: g}) 
                    useDataStore.setState({PEFestMilestone: m}) 
                    useDataStore.setState({PEFestCategory: c}) 
                    useDataStore.setState({PEFestCritery: cr}) 
                    useDataStore.setState({PEFestSchool: sc}) 
                    // console.log( useDataStore.getState()  )
                })
                .catch( err => console.error(err) )

        })
        .catch( err => {
            console.error(err)
            useMainStore.setState({ landId: festId })
            setValue(false)
        }) 
}

export {useFestival, clearFestival}