import { Collapse, Intent, Tag } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { GET_ALL_ROLES_ACTION, GET_FEED_ACTION, UPDATE_SINGLE_TYPE } from "src/modules/pe-basic-module/data/actionTypes"
import actions from "src/modules/pe-basic-module/data/actions"
import { IUser } from "src/settings/zustand"
import { Json, Role } from "src/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "src/libs/scalars"
import { AppToaster, Loading } from "src/libs/useful"
import { __ } from "src/libs/utilities" 
import UserSearchEngine from "src/libs/useful/UserSearchEngine"

/* Protopia Ecosystem component */
export interface IRoleCarriersFormProps {

}
const RoleCarriersForm = (props: IRoleCarriersFormProps): JSX.Element => { 
    const { landId } = useParams()
    const [allRoles, setAllRoles] = useState<Role[]>( [] ) 
    const [user, setUser] = useState<IUser>( {} as IUser ) 
    const [isLoading, setIsLoading] = useState<boolean>( false )
    useEffect(() => { 
        actions(GET_ALL_ROLES_ACTION, {}).then(res => {
            setAllRoles(res)
            setIsLoading(false)
        })
    }, [])
    if( isLoading ) {
        return <div className="layout-state flex-centered">
            <Loading />
        </div>
    }
     
    const onUser = (value: IUser) => {
        console.log( value )
        setUser( value )
    }
    const onUserRoles = (value: Role[]) => {
        let u:any = {roles: value}
        setUser({...user, roles: value})
        delete u.__typename
        delete u.id
        delete u.external
        delete u.externalId
        delete u.externalId
        actions(
            UPDATE_SINGLE_TYPE, 
            {
                id: user.id, 
                data_type: "User",
                item: u,
                landId
            }
        )
        .then(( response ) => {
            console.log( response )
            AppToaster.show({
                message: __("Success update user's roles"),
                icon: "person"
            })
        })
    }

    // console.log( user )

    return <div className='pe-role-carriers-form-container short-container'>
        <div className="my-4 text-uppercase">
            { __( "Choose users and append|remove roles" ) }
        </div> 
        <UserSearchEngine 
            user={user}
            onSelect={ onUser }
            landId={landId}
        />
        <Collapse isOpen={ !!user?.id && user?.id !== "-1" }>
            <div className="d-flex w-100 mb-5 mt-3">
                <div className="pr-4">
                    <div 
                        className="user-ava square2 mx-auto position-relative" 
                        style={{backgroundImage:`url(${user?.avatar})`}} 
                    >
                        {
                            user?.external &&
                            <span className={`${user?.external} soc sm position-absolute bottom right rounded-circle`} >
                                <i className={`fab fa-${user?.external} `} />
                            </span>
                        }
                    </div>
                    {
                        user?.is_blocked &&
                        <Tag intent={Intent.WARNING}>{__("Blocked")}</Tag>
                    }
                    <div className="mt-3">
                        { user?.user_descr}
                    </div>
                </div> 
                <div className="flex-grow-100 w-100">
                    <FieldInput
                        type={ SCALAR_TYPES.CHECKBOX }
                        value={ user?.roles }
                        multiple
                        editable
                        values={ allRoles }
                        onChange={ onUserRoles }
                    />
                </div> 
            </div>
        </Collapse> 
    </div>
}
export default RoleCarriersForm