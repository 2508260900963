import { useDataStore } from "src/modules/pe-basic-module/data/store"
import { useEffect } from "react"
import { IPartner } from "../data/interfaces"
import ToposPartner from "./ToposPartner"
import actions from "src/modules/pe-basic-module/data/actions"
import { GET_FEED_ACTION } from "src/modules/pe-basic-module/data/actionTypes"
import { ID } from "src/libs/interfaces/layouts"
import { IFestDataStor, useFestDataStore } from "src/modules/pe-fest-module/data/store"
import { __ } from "src/libs/utilities"

/* Protopia Ecosystem component */
export interface ILandPartnersWidgetProps {
   
}
const LandPartnersWidget = (props: ILandPartnersWidgetProps): JSX.Element => {
    const partners: IPartner[] = useDataStore((state: any) => state.PEToposPartner ) || []
    const land_id: ID = useFestDataStore((state: IFestDataStor) => state.festId)
    useEffect(() => {
        // console.log( land_id )
        actions(GET_FEED_ACTION, {data_type: "PEToposPartner", land_id})
    }, [ land_id ])
    return !!partners.length
        ?
        <div className='pe-land-partners-widget-container py-5'> 
            <div className=" d-flex justify-content-center pb-3">
                <div className="role_descr">
                    {__("Our partners")}
                </div>
            </div>
            <div className="container d-flex justify-content-center flex-wrap ">
                {
                    partners.map(p => <ToposPartner item={p} />)
                }
            </div>
            
        </div>
        :
        <></>
}
export default LandPartnersWidget