import { ID, WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import { Loading } from '@/libs/useful'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { getQueryData } from 'src/libs/layouts'
import Feed from 'src/modules/pe-basic-module/views/core/Feed'
import { CHANGE_CARD_SORT_ACTION, CHANGE_CARD_TYPE_ACTION } from '../../data/actionTypes'
import { actions } from '../../data/actions'
import { cardTypes } from '../../data/cardTypes'
import { CARD_SORT_TYPE, CARD_TYPE, IFestFilters, IFestival, IGanre, IHoneycombs, IPEFestSchool, IProject, IProjectListProps } from '../../data/interfaces'
import { IFestDataStor, IFestStore, useFestDataStore, useFestStore } from '../../data/store'
import { getGQLPagingFilters } from '../../data/utils/getPaging'
import { useFestival } from '../../hooks/festival'
import { useMyProjects } from '../../hooks/project'
import PListLayout from './PListLayout'
import SimpleStrokeProject from './SimpleStrokeProject'
import PEFestProjectTerm from './PEFestProjectTerm'


const ProjectListForm = (props: IProjectListProps) : JSX.Element => {
    
    const params = useParams()
    const festId: ID = params.landId || "-1" 
    // пагинация для фильтров ТРЕКИ, СОТЫ, ЖАНРЫ
    const filters: IFestFilters     = useFestStore( (state:IFestStore) => state.filters )
    const cardType: CARD_TYPE       = useFestStore( (state: IFestStore) => state.cardType )
    const cardSort: CARD_SORT_TYPE  = useFestStore( (state: IFestStore) => state.cardSort )
    const search: string            = useFestStore( (state: IFestStore) => state.search )
    const isVerified: boolean       = useFestStore( (state:IFestStore) => state.isVerified )
    const isNotVerified: boolean    = useFestStore( (state:IFestStore) => state.isNotVerified )
    
    const festival: IFestival = useFestDataStore((state:IFestDataStor) => state.festival )
    const [offset, setOffset] = useState<number>(0)  
    const [fullCount, setFullCount] = useState<number>(0)
    const over = ``
    const [paging, setPaging] = useState<string>( "" )
    const [isLoading2, setIsLoading2] = useState<boolean>( true )
    const [URLSearchParams, SetURLSearchParams] = useSearchParams()

    const updateURLSearchParams = () => {
        let params: any = {}
        if(filters.tracks[0] !== -1) {
            params.tack = filters.tracks.join(",")
        }
        if(filters.schools.length > 1) {
            params.schools = filters.schools.join(",")
        }
        if(filters.ganres.length > 1) {
            params.ganres = filters.ganres.join(",")
        }
        SetURLSearchParams( params )
    }
    useEffect(() => {
        const tracks = URLSearchParams.get("track")
        if(tracks) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    tracks: [ Number(tracks) ]}
                })
        }
        const schools = URLSearchParams.get("schools")
        if(schools) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    schools: schools.split(",") }
                })
        }
        const ganres = URLSearchParams.get("ganres")
        // console.log( ganres )
        if(ganres) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    ganres: ganres.split(",") }
                })
        }
        const _paging = getGQLPagingFilters( filters, cardSort, search, over )
        setPaging( _paging )
        console.log( _paging, 1)
        setIsLoading2(false)
    }, [])

    useEffect(() => {
        setOffset(0) 
        const _paging = getGQLPagingFilters( filters, cardSort, search, over )
        setPaging( _paging )
        updateURLSearchParams()
        console.log( _paging, filters)
        
    }, [ filters, cardSort, search, isVerified, isNotVerified ])

    const isLoading = useFestival(!props.parentMode, festId) 

    // получаем список проектов текущего пользователя       
    const myProjects: IProject[] = useMyProjects( festId ) 
    const data_type = "PEFestProject"

    if(isLoading || isLoading2 ) return <Loading /> 
    
    const onCardType = ( cardType: CARD_TYPE ) => { 
        actions(CHANGE_CARD_TYPE_ACTION, {cardType})
    }
    const onCardSort = ( cardSort: CARD_SORT_TYPE ) => { 
        actions(CHANGE_CARD_SORT_ACTION, { cardSort })
    } 
    const onLoading = ( items: any[],  offset: number, count: number, paging: string, fullCount: number ) => {
        setFullCount(fullCount)
    }
    // console.log( cardType, festival.memberCardType )

    const cardComponent = festival.isChangeCardType
        ?
        cardType
            ?
            cardTypes().filter( ct => ct.type === cardType )[0]?.card || SimpleStrokeProject
            :
            cardTypes().filter( ct => ct.type === festival.memberCardType )[0]?.card || SimpleStrokeProject
        :
        cardTypes().filter( ct => ct.type === festival.memberCardType )[0]?.card || SimpleStrokeProject
            
    return (
        <div className="mx-auto w-100">
            <PEFestProjectTerm />
            <div className='mx-auto container p-0'>
                <Feed 
                    data_type={data_type}  
                    offset={offset} 
                    setOffset={ offset => setOffset(offset) }
                    paging={ paging }
                    land_id={ festId } 
                    class_name='pl-2'
                    feedBefore={ offset === 0 ? getMyProjects(myProjects, filters, search, cardSort, isVerified, isNotVerified) : [] }
                    filter={ myProjects.map(p => p.id ) }
                    containerClassName="" 
                    layoutComponent={ PListLayout }
                    component={ cardComponent }
                    topPagiHide
                    onLoading={onLoading}
                    params={{
                        fields: getQueryData( data_type ),
                        route: props.route,
                        cardType,
                        cardSort, 
                        onCardSort,
                        onCardType,
                        fullCount : fullCount 
                    }}
                />
            </div>
        </div>
    )
} 

export default ProjectListForm

// проекты текущего пользователя, отфильтрованные по глобальному фильтру
const getMyProjects = (myProjects: IProject[], filters: IFestFilters, search: string, cardSort: CARD_SORT_TYPE, isVerified:boolean, isNotVerified:boolean ): IProject[] => { 
    if( search ) return myProjects
        .filter(p => {
            return p.title.indexOf(search) > -1 ||
            p.post_content.indexOf(search) > -1
        }  ) 

    const prs: IProject[] = myProjects.filter((p:IProject) => {
        console.log( filters )
        if( filters.tracks[0] !== -1 ) {
            return p.track?.id === filters.tracks[0]
        }
        if( filters.honeycombs[0] !== -1 ) {
            return filters.honeycombs[0] === "mine"
                ?
                filters.honeycombs.includes( p.honeycombs?.id )
                :
                p.honeycombs?.id === filters.honeycombs[0]
        }
        if( filters.ganres.length > 1 ) {
            return !!p.fmru_group_player.filter((ganre: IGanre) => {
                return filters.ganres.includes(ganre.id)
            }).length 
        }
        if( filters.destrict[0] !== -1 ) {
            // console.log( filters.destrict[0] )
            // return filters.destrict[0]
            //     ?
            //     !![...schools].filter(fmru_school => {
            //         return  fmru_school.destrict[0] && fmru_school.destrict[0]?.id === filters.destrict[0]
            //     }).length
            //     :
            //     false
        }
        if( filters.schools.length > 1 ) {
            return !!p.fmru_school.filter(fmru_school => {
                return filters.schools.includes(fmru_school.id)
            }).length 

        }
        if(isVerified && isNotVerified) {}
        else if( isVerified)
            return !!p.is_verified 
        else if( isNotVerified )
            return !p.is_verified 
        return true
    })
    prs.sort((a: IProject, b: IProject): number => {
        if( cardSort === CARD_SORT_TYPE.ACS) {
            if( a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() )
                return 1
            else return -1
        }
        if( cardSort === CARD_SORT_TYPE.DECS) {
            if( a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() )
                return -1
            else return 1
        }
        if( cardSort === CARD_SORT_TYPE.NACS) {
            if( a.order > b.order )
                return 1
            else return -1
        }
        if( cardSort === CARD_SORT_TYPE.NDECS) {
            if( a.order > b.order )
                return -1
            else return 1
        }
        if( cardSort === CARD_SORT_TYPE.RACS) {
            if( a.raiting > b.raiting )
                return 1
            else return -1
        }
        if( cardSort === CARD_SORT_TYPE.RDECS) {
            if( a.raiting > b.raiting )
                return -1
            else return 1
        }

        return 0
    })
    return prs
}